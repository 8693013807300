<template>
  <b-card
    id="shipment-statuses"
    no-body
    class="p-1"
  >
    <app-collapse>
      <app-collapse-item
        title="Shipment Statuses"
        header-style="h4-style"
        @visible="loadShipmentData"
      >
        <b-table
          class="mt-2"
          :fields="shipmentsStatisticsFields"
          :items="shipmentStatistics"
          :busy="!isDataLoaded"
          show-empty
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(name)="data">
            <router-link :to="data.item.link">
              <strong :class="data.item.class">{{ data.item.name }}</strong>
            </router-link>
          </template>

          <template #cell(available)="data">
            <router-link :to="data.item.link">
              <strong
                class="text-primary"
                v-b-tooltip.hover.top="'Available'"
                :class="data.item.class"
              >
                {{ data.item.available }}
              </strong>
            </router-link>
          </template>

          <template #cell(done)="data">
            <router-link :to="data.item.link">
              <strong
                  class="text-success"
                  v-b-tooltip.hover.top="'Done'"
                  :class="data.item.class"
              >
                {{ data.item.done }}
              </strong>
            </router-link>
          </template>

        </b-table>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BTable, BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BSpinner,
    AppCollapseItem,
    AppCollapse,
    BCard,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['shipmentsStatisticsFields', 'shipmentStatistics', 'shipmentId', 'eventHub', 'isDataLoaded'],
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    loadShipmentData() {
      if (!this.isDataLoaded) {
        this.eventHub.$emit('load-shipment-statistics')
        this.isOpen = true
      }
    },
  },
}
</script>
