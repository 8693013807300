<template>
  <b-modal
    :id="this.$attrs.id"
    ref="view-audit-modal"
    title="View changes"
    size="lg"
    scrollable
  >
    <b-card-text>
      <b-row v-if="audit.tracking_number">
        <b-col>
          <label>Tracking number</label>
          {{ audit.tracking_number }}
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <label>Old value</label>
          <b-form-textarea
            :value="JSON.stringify(this.audit.old_values, null, '\t')"
            :disabled="true"
            class="mr-0 ml-0"
            rows="20"
          />
        </b-col>
        <b-col>
          <label>New value</label>
          <b-form-textarea
            :value="JSON.stringify(audit.new_values, null, '\t')"
            :disabled="true"
            class="mr-0 ml-0"
            rows="20"
          />
        </b-col>
      </b-row>
    </b-card-text>

    <template #modal-footer="{ ok }">

      <b-container fluid>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="primary"
              @click="ok()"
            >
              Close
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BContainer,
  BRow,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCol,
    BContainer,
    BRow,
    BModal,
    BCardText,
    BFormTextarea,
  },
  props: {
    audit: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
