const shipmentImportIssues = {
  tables: {
    parcel: 'Parcel',
    parcel_item: 'Parcel item',
  },
  issue_codes: {
    empty: 'Empty',
    incorrect_value: 'Incorrect value',
  },
}

export default shipmentImportIssues
