<template>
  <b-card
    v-if="$activeCompany().data.company.config && $activeCompany().data.company.config.hasMindsoft && shipment && shipment.id"
    title="MindSoft"
  >
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="outline-success"
          class="m-1"
          :href="mindSoftUrl"
        >
          Download HGL (MindSoft) Excel
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
  props: ['shipment'],
  data() {
    return {
      mindSoftUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/mindsoft/messages`,
    }
  },
}
</script>
