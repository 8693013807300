<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card
      id="ens-form"
      title="ENS"
    >
      <b-row
        align-h="center"
        class="text-center"
      >
        <b-col
          cols="6"
        >
          <div class="text-center">
            <p v-if="!latestDeclaration">
              ENS has not yet been sent
            </p>
            <p
              v-if="statusMessage"
              v-html="statusMessage"
            />
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="text-center"
      >
        <b-col>
          <b-dropdown
            id="submit-ens"
            variant="primary"
            :disabled="requestPending || manifestHasIssues"
          >
            <template #button-content>
              Submit ENS house-filings
              <b-spinner
                v-if="requestPending"
                small
              />
            </template>
            <b-dropdown-item
              @click="sendEns(['F26'], 'pre-loading')"
            >Pre-loading</b-dropdown-item>
            <b-dropdown-item
              @click="sendEns(['F22'], 'pre-arrival')"
            >Pre-arrival</b-dropdown-item>
          </b-dropdown>
          <b-row class="mt-1 justify-content-md-center">
            <b-col :cols="manifestHasIssues ? 4 : 6">
              <b-alert
                :show="manifestHasIssues || errors !== ''"
                variant="danger"
              >
                <span v-if="manifestHasIssues">Fix parcel issues before sending ENS request</span>
                <span v-if="errors">{{ errors }}</span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="canSeeEnsTable">
            <b-col>
              <b-table
                :items="declarations"
                responsive
                :fields="fields"
                :tbody-tr-class="rowClass"
                stacked="sm"
                class="mb-0"
                show-empty
              >
                <template #cell(status)="data">
                  <v-select
                    v-model="data.item.status"
                    :options="statusList"
                    :clearable="false"
                    :disabled="!statusList.includes(data.item.status)"
                    input-id="status"
                    @input="updatedStatus(data.item.id, data.item.status)"
                  />
                </template>
                <template #cell(bundles)="data">
                  <b-list-group>
                    <b-list-group-item
                      v-for="bundle in data.item.bundles"
                      :key="bundle.lrn"
                      class="d-flex justify-content-between"
                    >
                      <span class="mr-1">{{ bundle.message_type }}</span>
                      <a
                        v-if="bundle.url"
                        :href="bundle.url"
                        target="_blank"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="d-inline"
                        />
                        {{ bundle.lrn }}
                      </a>
                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    :disabled="data.item.declarations > 0"
                    variant="outline-danger"
                    size="sm"
                    @click="deleteEns(data.item.id)"
                  >
                    <feather-icon
                      v-if="deletingId !== data.item.id"
                      icon="TrashIcon"
                      class="d-inline"
                    />
                    <b-spinner
                      v-else
                      small
                    />
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BAlert,
  BSpinner,
  BOverlay,
  BTable,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BTable,
    BCard,
    BCol,
    BRow,
    BAlert,
    BButton,
    BSpinner,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
  },
  props: ['shipment', 'statistics'],
  data() {
    return {
      loading: false,
      requestPending: false,
      status: '',
      errors: '',
      deletingId: null,
      declarations: this.shipment.ens_declarations || [],
      statusMessage: '',
      fields: [
        { key: 'bundles', label: 'Bundles' },
        { key: 'type', label: 'Type' },
        { key: 'created_at', label: 'Created at' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
      statusList: [
        'WAITING FOR X7TRADE TEAM',
        'X7TRADE TEAM SUBMITTING FILES',
        'SUBMISSION TO STI-STP FAILED',
        'SUBMITTED',
      ],
    }
  },
  computed: {
    latestDeclaration() {
      return this.declarations.length > 0 ? this.declarations[0] : null
    },
    manifestHasIssues() {
      return this.statistics.prohibited?.h7 > 0 || this.statistics.reducedVat?.h7 > 0 || this.statistics.missingHsCodes?.h7 > 0
    },
    canSeeEnsTable() {
      return this.$permissions().hasRole('Feeport Admin')
    },
  },
  created() {
    this.getDeclarations()
    const channel = this.$pusher.subscribe(`shipment-ens-${this.shipment.id}`)

    channel.bind('ens-status-update', (status, statusMessage) => {
      if (status !== 'WAITING FOR X7TRADE TEAM') {
        this.requestPending = false
        this.getDeclarations()
      }
      this.statusMessage = statusMessage
    })
  },
  methods: {
    sendEns(messageTypes, requestType) {
      this.requestPending = true
      this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/ensDeclarations`, {
        messageTypes: messageTypes,
        declarationType: requestType,
      })
        .then(response => {
          this.declarations.unshift(response.data.data)
          this.statusMessage = this.latestDeclaration ? this.latestDeclaration.statusMessage : ''
          this.errors = ''
        })
        .catch(error => {
          handleError(error, this.$toast)
          this.requestPending = false
        })
    },
    getDeclarations() {
      this.loading = true
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/ensDeclarations`)
        .then(response => {
          this.declarations = response.data.data
          this.latestDeclaration = this.declarations[this.declarations.length - 1]
          this.statusMessage = this.latestDeclaration ? this.latestDeclaration.statusMessage : ''
          this.errors = this.latestDeclaration ? this.latestDeclaration.status_details : ''
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteEns(id) {
      this.deletingId = id
      this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/ensDeclarations/${id}`)
        .then(() => {
          this.deletingId = null
          this.getDeclarations()
        })
    },
    updatedStatus(id, status) {
      this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/ensDeclarations/${id}`,
        {
          status: status,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Status updated',
            },
          })
        })
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.id !== this.latestDeclaration.id) return 'table-secondary'
      return ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
