<template>
  <b-card title="Export shipment">
    <b-card-text class="text-right">
      <b-button
        v-b-modal.export-modal
        v-b-tooltip.hover.top="'Download Shipment Export File'"
        variant="outline-success"
        @click="exportShipment"
      >
        Export
      </b-button>
    </b-card-text>
  </b-card>
</template>
<script>
import { BButton, BCard, BCardText, VBTooltip } from 'bootstrap-vue'
import handleError from '@/views/components/errorHandler'
import moment from 'moment/moment'

export default {
  components: {
    BButton,
    BCardText,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['shipmentId'],
  methods: {
    exportShipment() {
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/export`)
        .then(response => {
          this.$bvModal.hide('export-modal')
          const time = moment().format('YYYY-MM-DD')
          this.startDownload(response, `${time}_export_declaration.xml`)
          this.$emit('done')
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    startDownload(response, fallbackFileName = '') {
      const filename = response.headers['content-disposition']?.split('=')[1] || fallbackFileName
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xml' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
}
</script>
